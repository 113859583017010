import { Timeline } from "@mui/lab"
import CustTimelineItem, { DOT_TYPE } from "./CustTimelineItem"

const items = [
  {
    title: "第一次見面",
    description: "她和他的戀愛花期",
    date: "25/9/2021",
    iconType: DOT_TYPE.ANNIVERSARY,
  },
  {
    title: "見面",
    description: "蕃茄麵",
    date: "1/10/2021",
    iconType: DOT_TYPE.DATE,
  },
  {
    title: "約會",
    description: "Pixer & Meseum of Art",
    date: "3/10/2021",
    iconType: DOT_TYPE.DATE,
  },
  {
    title: "表白",
    description: "溫日文 & Jojo表白",
    date: "7/10/2021",
    iconType: DOT_TYPE.ANNIVERSARY,
  },
  {
    title: "正式一齊",
    description: "八號風球 午夜天鵝",
    date: "9/10/2021",
    iconType: DOT_TYPE.ANNIVERSARY,
  },
  {
    title: "約會",
    description: "Corefy 溫日文",
    date: "14/10/2021",
    iconType: DOT_TYPE.DATE,
  },
  {
    title: "約會",
    description: "第一次煮飯仔 我老婆日日都扮死",
    date: "16/10/2021",
    iconType: DOT_TYPE.DATE,
  },
  {
    title: "約會",
    description: "馬灣",
    date: "17/10/2021",
    iconType: DOT_TYPE.DATE,
  },
  {
    title: "約會",
    description: "Hana紋身",
    date: "19/10/2021",
    iconType: DOT_TYPE.DATE,
  },
  {
    title: "約會",
    description: "明天，我要和昨天的妳約會",
    date: "23/10/2021",
    iconType: DOT_TYPE.DATE,
  },
  {
    title: "約會",
    description: "文記車仔麵 買南瓜Cosplay",
    date: "28/10/2021",
    iconType: DOT_TYPE.DATE,
  },
  {
    title: "約會",
    description: "阿元來了 Lunch",
    date: "29/10/2021",
    iconType: DOT_TYPE.DATE,
  },
  {
    title: "派對",
    description: "牛雜Halloween Party",
    date: "31/10/2021",
    iconType: DOT_TYPE.DATE,
  },
  {
    title: "約會",
    description: "Corefy 溫日文",
    date: "4/11/2021",
    iconType: DOT_TYPE.DATE,
  },
  {
    title: "約會",
    description: "蒲台島",
    date: "6/11/2021",
    iconType: DOT_TYPE.DATE,
  }
]

const CustTimeline = (props) => {
  return <Timeline position="alternate">
    {items.map((item, i) =>
      <CustTimelineItem
        key={i}
        index={i}
        {...item}
      />)}
  </Timeline>

}

export default CustTimeline