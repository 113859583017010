import { Loyalty, Star } from '@mui/icons-material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import { Typography } from "@mui/material";

export const DOT_TYPE = {
  DATE: 0,
  ANNIVERSARY: 1,
}

const getDot = (type) =>
  type === DOT_TYPE.ANNIVERSARY ? <FavoriteIcon /> :
    type === DOT_TYPE.DATE ? <Loyalty /> :
      <Star />

const CustTimelineItem = ({
  iconType,
  date,
  title,
  description,
  index,
  ...props
}) => {
  return <TimelineItem>
    <TimelineOppositeContent
      sx={{ m: 'auto 0' }}
      align={index % 2 === 0 ? 'right' : 'left'}
      variant="body2"
      color="text.secondary"
    >
      {date}
    </TimelineOppositeContent>
    <TimelineSeparator>
      <TimelineConnector />
      <TimelineDot>
        {getDot(iconType)}
      </TimelineDot>
      <TimelineConnector />
    </TimelineSeparator>
    <TimelineContent sx={{ py: '12px', px: 2 }}>
      <Typography variant="h6" component="span">
        {title}
      </Typography>
      <Typography>{description}</Typography>
    </TimelineContent>
  </TimelineItem>
}

export default CustTimelineItem