import { useState } from "react"
import bg from "../image/bg.jpg"
import bg2 from "../image/bg2.jpg"
import fox from "../image/fox.jpg"
import icon from "../image/icon.png"
import lastImage from "../image/last.jpg"
import message from "../image/message.jpg"
import rose from "../image/rose.jpg"
import self from "../image/self.jpg"
import CustTimeline from "../timeline/CustTimeline"

const DownArrow = (props) => {
  const { isBlack } = props
  return <div className="overlay-bottom"><i class={"arrow down" + (isBlack ? " black" : "")}></i></div>
}

const First = (props) => {
  return <section className="card">
    <img className="bg" src={bg} />
    <div className="overlay" style={{ top: "40%" }}>
      <img className="icon" src={icon} />
      <div className="icon-text">Hanaへ</div>
      <div className="icon-content">
        <p>除咗妳quote果幾幕之外</p>
        <p>以下喺我幾鍾意嘅...</p></div>
    </div>
    <DownArrow />
  </section>
}

const Sheep = (props) => {
  const [hold, setHold] = useState(false)
  return <section className="card">
    <img className="bg" src={rose} />
    <div className="overlay-top">
      如果有人鍾愛著一朵獨一無二的、盛開在浩瀚星海里的花。那麼，當他抬頭仰望繁星時，便會心滿意足。他會告訴自己：「我心愛的花在那裏，在那顆遙遠的星星上。」
    </div>
    <div className="overlay" onClick={() => setHold((v) => !v)} style={{ top: "68%" }}>
      <div className="prince-text" style={hold ? {
        color: "white",
        background: "grey",
      } : {}}>
        {(!hold) ?
          <>
            <p>「...如果綿羊仔食咗朵花，噉對佢嚟講，</p>
            <p>就會好似所有星光都突然熄滅一樣!」</p>
          </> :
          <>
            <p></p>
            <p></p>
          </>
        }
      </div>
    </div>
    <DownArrow isBlack={true} />
  </section>
}

const Fox = (props) => {
  const [hold, setHold] = useState(false)
  return <section className="card">
    <img className="bg center" src={fox} />
    <div className="overlay-top">
      「對我來說，你只是一個小男孩，就像其他成千上萬個小男孩一樣沒有什麼兩樣。我不需要你。你也不需要我。對你來說，我也只是一隻狐狸，和其他成千上萬的狐狸沒有什麼不同。但是，如果你馴養了我，我們就會彼此需要。對我來說，你就是我的世界裏獨一無二的了；我對你來說，也是你的世界裏的唯一了。」
    </div>
    <div className="overlay" onClick={() => setHold((v) => !v)} style={{ top: "68%" }}>
      <div className="prince-text" style={hold ? {
        color: "white",
        background: "grey",
      } : {}}>
        {(!hold) ?
          <>
            <p>「有一朵花，我諗我一定係畀佢馴服咗。」</p>
          </> :
          <>
            <p>初めて会った日から</p><p>僕の心の全てを奪った</p>
          </>
        }
      </div>
    </div>
    <DownArrow isBlack={true} />
  </section >
}

const Message = (props) => {
  return <section className="card">
    <img className="bg center" src={bg2} />
    <div className="overlay" style={{ top: "45%" }}>
      <img className="message-image" src={message} />
      <div className="message-text">
        <p style={{ textAlign: "left", marginBottom: "8px" }}>Hanaへ</p>
        <p>{"多謝妳送呢本童話畀我，果晚見到你嘅留言之後我開心到成晚都瞓唔著><"}</p>
        <p style={{ marginTop: "8px" }}>可以識到妳，我真係好開心好幸福!! 最後我有一句話想同妳講...</p>
      </div>
    </div>
    <DownArrow />
  </section>
}

const Meeting = (props) => {
  return <section className="card">
    <div style={{ height: "100%", overflowY: "scroll" }}>
      <CustTimeline />
    </div>
    <DownArrow />
  </section>
}

const Last = (props) => {
  const [hold, setHold] = useState(false)
  return <section className="card">
    <img className="bg" style={{ width: "100%" }} src={lastImage} />
    <div className="overlay" onClick={() => setHold((v) => !v)} style={{ top: "68%" }}>
      <div className="last-text" style={hold ? {
        color: "white",
        background: "grey",
      } : {}}>
        {(!hold) ?
          <p>我愛妳</p>
          :
          <p>愛してる！</p>
        }
      </div>
    </div>
    <DownArrow />
  </section>
}

const MyMessage = (props) => {
  return <section className="card">
    <img className="bg center" src={self} />
    <div className="overlay">
      <div className="mymessage-text">
        <p>僕のこと。</p>
        <p>人には表と裏があると思います。少なくとも僕はそうです。</p>
        <p>僕の表と裏はまるで全く別人のようです。</p>
        <p>裏の僕は蟹にそっくり、自分の姿を隠すように、誰にも見せないように、</p>
        <p>只々自分だけの前に生きています。</p>
        <p>この僕は、性格が面倒くさくて弱くて、すごくネガティブです。</p>
        <p>言葉や気持ちをはっきり言ってくれないと、</p>
        <p>僕は消極的なあらゆる面に囲まれて自己否定したり自己嫌悪したりしまいます。</p>
        <p>そんな僕は愛がほしい、抱きしめてほしい、愛する人が傍にいてほしいです。</p>
        <p>ただ、愛を求めている裏の僕は表には出てこない、出ることができないです。</p>
        <p>裏にいるからです。</p>
        <p>そんな裏の僕は表の俺が守っています。</p>
        <p>仮面をかぶって自分を自分が守るために俺が出てくるんです。</p>
        <p>この俺は、明るくて楽観的で元気でしています。</p>
        <p>この姿は偽りではなくて、俺の生きる姿だけなんです。</p>
        <p>つづく</p>
      </div>
    </div>
  </section>
}

const Main = (props) => {

  return <>
    <First />
    <Fox />
    <Sheep />
    <Message />
    <Last />
    <Meeting />
    <MyMessage />
  </>
}

export default Main